import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import { isElementInViewport } from '../functions'
import axios from 'axios'
import mapMarker from '../../assets/images/map/marker.png'
import mapMarkerLarge from '../../assets/images/map/marker-large.png'
import Select from 'react-select'

const ProductLocator = ({ pageTitle, pageCopy }) => {
  const [isInView, setIsInView] = useState(false)
  const refSearchElmsSection = useRef(null)
  const [listWrapActive, setListWrapActive] = useState(false)
  const [mapWrapActive, setMapWrapActive] = useState(false)
  const [tabMapBtnDisabled, setTabMapBtnDisabled] = useState(false)
  const [tabListBtnDisabled, setTabListBtnDisabled] = useState(true)
  const [productFinderReactSelectOptions, setProductFinderReactSelectOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [groupedBrandFlavors, setGroupedBrandFlavors] = useState([])
  const google = useRef(null)

  const mapClickfunc = () => {
    if (tabMapBtnDisabled) {
      return
    }
    setTabMapBtnDisabled(!tabMapBtnDisabled)
    setTabListBtnDisabled(!tabListBtnDisabled)
    setMapWrapActive(!mapWrapActive)
    setListWrapActive(!listWrapActive)
  }

  const listClickfunc = () => {
    if (tabListBtnDisabled) {
      return
    }
    setTabListBtnDisabled(!tabListBtnDisabled)
    setTabMapBtnDisabled(!tabMapBtnDisabled)
    setMapWrapActive(!mapWrapActive)
    setListWrapActive(!listWrapActive)
  }

  const brandLocatorOnChange = (e) => {
    setSelectedOptions(Array.isArray(e) ? e.map(x => x.value) : [])
    brand.brandSelector(Array.isArray(e) ? e.map(x => x.value) : [])
  }

  const brand = {
    map: null,
    brandId: null,
    getProductsURL: null,
    getStoreListForURL: null,
    groupedBrandFlavors: null,
    productFinderBrandSelector: null,
    productFinderFlavorSelector: null,
    productFinderPremiseSelector: null,
    productFinderBrandSelectorOptions: null,
    addressInputIDelm: null,
    // locateMeBtn: null,
    el: null,
    defaultFlavor: null,
    brandFlavor: null,
    checkDragMap: false,
    lat: null,
    lng: null,
    productID: null,
    addressInputID: null,
    premiseTypeID: null,
    countryID: null,
    languageStatus: null,
    brandIconMapPin: mapMarker,
    brandIconMapPinLarge: mapMarkerLarge,
    businesses: null,
    locations: null,
    zoom: 14,

    // Mobile device user location getter function via navigatior.geolocation / global geoloctaion object
    // requestLocation: () => {
    //   document.getElementById('loading-container').classList.add('visible')
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //     brand.lat = position.coords.latitude
    //     brand.lng = position.coords.longitude
    //     brand.getStoreList()
    //   })
    // },

    getDomElems: () => {
      brand.productFinderBrandSelector = document.getElementById('product-finder-brand-selector')
      brand.productFinderFlavorSelector = document.getElementById('product-finder-flavor-selector')
      brand.productFinderPremiseSelector = document.getElementById('product-finder-premise-selector')
      brand.addressInputIDelm = document.getElementById('product-finder-address')
      // brand.el = document.getElementById('product-finder-form')
      // brand.locateMeBtn = document.getElementById('product-finder-locate-me')
    },

    locatorResults: () => {
      const locatorResultsElm = document.getElementById('main-locator-results')
      // locatorResultsElm.addEventListener('click', function (e) {
      //   // Data layer - Locator Addresse Searched
      //   var brandSelect = document.getElementById('product-finder-brand-selector');
      //   var flavorSelect = document.getElementById('product-finder-flavor-selector');

      //   var flavorName = brandSelect.options[brandSelect.selectedIndex].text + ' - ' + flavorSelect.options[flavorSelect.selectedIndex].text;
      //   var supplierName = e.target.closest(".location").querySelector('.store-name').innerHTML;

      //   if (flavorName !== "" && supplierName !== "") {
      //     window.dataLayer = window.dataLayer || [];
      //     window.dataLayer.push({
      //       "event": "locator_supplier_selected",
      //       "supplier_name": supplierName.toLowerCase(),
      //       "product_flavor": flavorName.toLowerCase()
      //     });
      //   }
      //   // Eof data layer - locator addresses searched
      // })
      locatorResultsElm.addEventListener('mouseover', function () {
        if (this.querySelectorAll('.location').length) {
          this.classList.add('main-locator-results-active')
        }
      })
      locatorResultsElm.addEventListener('mouseout', function () {
        this.classList.remove('main-locator-results-active')
      })
    },

    setLocationBasedVariableValues: () => {
      brand.brandId = 1035 // API KEY FOR CANADA
      brand.countryID = "ca" // CANADA COUNTRY CODE FOR GOOGLE API
      // ==================================>
      // Local
      // ==================================>
      // brand.getProductsURL = 'http://localhost:56775/Products' // GET PRODUCTS FOR CANADA
      // brand.getStoreListForURL = 'http://localhost:56775/Accounts' // GET STORE LIST FOR CANADA
      // ==================================>
      // Dev
      // ==================================>
      // brand.getProductsURL = 'https://storelocatorcan-api-dev.markanthony.com/Products' // GET PRODUCTS FOR CANADA
      // brand.getStoreListForURL = 'https://storelocatorcan-api-dev.markanthony.com/Accounts' // GET STORE LIST FOR CANADA
      // ==================================>
      // Prod
      // ==================================>
      // brand.getProductsURL = 'https://storelocatorcan-api.markanthony.com/Products' // GET PRODUCTS FOR CANADA
      // brand.getStoreListForURL = 'https://storelocatorcan-api.markanthony.com/Accounts' // GET STORE LIST FOR CANADA
      // Using the old URL end point for the API - Temporary solution until Cloud/Network team to resovle DNS issues with "https://storelocatorcan-api.markanthony.com"
      brand.getProductsURL = 'https://api.markanthony.com/StoreLocatorCAN/Products' // GET PRODUCTS LIST FOR CANADA
      brand.getStoreListForURL = 'https://api.markanthony.com/StoreLocatorCAN/Accounts' // GET STORE LIST FOR CANADA
    },

    getBrands: async () => {
      try {
        const response = await axios.get(brand.getProductsURL + '?id=' + brand.brandId)
        const responseData = response.data

        if (responseData) {
          let options = []

          for (var i = 0; i < responseData.length; i++) {
            if (responseData[i].Brand.Id === 0) {
              let brandFamilyName =  responseData[i].BrandFamily.Name
              let itemName =  responseData[i].Name
              responseData[i].Name = brandFamilyName + ' ' + itemName
            }
          }

          const groups = responseData.reduce((groups, item) => {
            const group = (groups[item.BrandFamily.Id] || [])
            group.push(item)
            groups[item.BrandFamily.Id] = group
            return groups
          }, {})

          for (const key in groups) {
            const arr = groups[key]
            const arrFirstChildObj = arr[0]
            const BrandFamilyId = arrFirstChildObj.BrandFamily.Id
            const BrandFamilyName = arrFirstChildObj.BrandFamily.Name
            options.push({ "value": BrandFamilyId, "label": BrandFamilyName })
          }

          brand.productFinderBrandSelectorOptions = options
          brand.groupedBrandFlavors = groups
          setProductFinderReactSelectOptions(options)
          setGroupedBrandFlavors(groups)
        } else {
          console.log('responseData: ', 'Response Error')
        }
      } catch (error) {
        console.log('responseData: ', 'error catch')
      }
    },

    populateBusinessList: () => {
      let businessList = ''
      brand.businesses.forEach(function (item, index) {
        item.markerListId = 'marker-list-' + index

        businessList += '<div id="' + item.markerListId + '-parent" class="location py-2 px-3">'
        businessList += '<a href="https://maps.google.com/maps?saddr=My+Location&daddr=' + encodeURI(item.Address + ' ' + item.Zip) + '" target="_blank" id="' + item.markerListId + '" class="store" data-target="marker-' + index + '">'
        businessList += '<span class="store-name">' + item.Name + '</span>'
        businessList += '<span class="address">' + item.Address + '</span>'
        businessList += '<span class="zip">' + item.City + ', ' + item.State + ' ' + item.Zip + '</span>'
        businessList += '</a>'
        businessList += '<span class="phone"><a href="tel:' + item.Phone + '">' + item.Phone + '</a></span>'
        businessList += '<a class="btn-go" href="https://maps.google.com/maps?saddr=My+Location&daddr=' + encodeURI(item.Address + ' ' + item.Zip) + '" target="_blank">Get Directions</i></a>'
        businessList += '</div>'

      })

      document.getElementById('flavor-list').innerHTML = businessList
      document.getElementById('loading-container').classList.remove('visible')

      let listItems = document.getElementById('flavor-list').querySelectorAll('.location .store')
      for (let i = 0; i < listItems.length; i++) {
        let el = document.getElementById(listItems[i].id)
        el.addEventListener('mouseover', function () {
          for (let q = 0; q < brand.businesses.length; q++) {
            if (this.id === brand.businesses[q].markerListId) {
              brand.businesses[q].marker.setIcon(brand.brandIconMapPinLarge)
              brand.map.panTo(brand.businesses[q].marker.getPosition())
            }
          }
        })

        el.addEventListener('mouseout', function () {
          for (let q = 0; q < brand.businesses.length; q++) {
            if (this.id === brand.businesses[q].markerListId) {
              brand.businesses[q].marker.setIcon(brand.brandIconMapPin)
            }
          }
        })
      }

      if (!brand.checkDragMap) {
        brand.map.setCenter(brand.businesses[0].marker.getPosition())
      } else {
        document.getElementById('loading-container-map-only').classList.remove('visible')
      }
    },

    loadMap: () => {
      brand.map = new google.current.maps.Map(document.getElementById('map-canvas'), {
        center: {
          lat: parseFloat(brand.lat),
          lng: parseFloat(brand.lng)
        },
        zoom: brand.zoom,
        disableDefaultUI: false,
        scrollwheel: false,
        0: true,
        scaleControl: true,
        zoomControlOptions: {
          position: google.current.maps.ControlPosition.LEFT_BOTTOM
        },
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map']
        }
      })

      let input = brand.addressInputIDelm
      let autocomplete = new google.current.maps.places.Autocomplete(input)

      // LOCATIONS ARRAY SET
      autocomplete.setComponentRestrictions({ 'country': [brand.countryID] })
      autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])

      autocomplete.addListener('place_changed', function () {

        let place = autocomplete.getPlace()
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          document.getElementById('form-no-results').innerHTML = 'No details available for input: ' + place.name
          document.getElementById('form-no-results').classList.add('visible')
          document.getElementById('flavor-list').innerHTML = ''
          !!brand.map && brand.removeMarkers()
          return
        }

        let mapLAT = autocomplete.getPlace().geometry.location.lat()
        let mapLNG = autocomplete.getPlace().geometry.location.lng()

        /*
        //Remove data attribute from the input
        var inputAddress = document.getElementById('product-finder-address');
        inputAddress.removeAttribute('data-st-num');
        inputAddress.removeAttribute('data-zc');
        inputAddress.removeAttribute('data-ctry');
        inputAddress.removeAttribute('data-st');
        inputAddress.removeAttribute('data-cty');
        inputAddress.removeAttribute('data-st-name');

        //Add data attribute to the input for later data layer use
        if (place.address_components !== undefined) {
          var searchedPlaceData = {};
          for (let idx = 0; idx < place.address_components.length; idx++) {
            var currentItem = place.address_components[idx];
            if (currentItem.types.includes('street_number')) { //address
              searchedPlaceData.streetNumber = currentItem.short_name;
              inputAddress.setAttribute('data-st-num', currentItem.long_name)
            } else if (currentItem.types.includes('postal_code')) { //zipcode
              searchedPlaceData.zc = currentItem.short_name;
              inputAddress.setAttribute('data-zc', currentItem.short_name);
            } else if (currentItem.types.includes('country')) { //country
              searchedPlaceData.ctry = currentItem.short_name;
              inputAddress.setAttribute('data-ctry', currentItem.short_name);
            } else if (currentItem.types.includes("administrative_area_level_1")) { //state
              searchedPlaceData.st = currentItem.long_name;
              inputAddress.setAttribute('data-st', currentItem.short_name);
            } else if (currentItem.types.includes("locality")) { //cty
              searchedPlaceData.cty = currentItem.long_name;
              inputAddress.setAttribute('data-cty', currentItem.long_name);
            } else if (currentItem.types.includes("route")) { //address 2
              searchedPlaceData.streetName = currentItem.short_name;
              inputAddress.setAttribute('data-st-name', currentItem.long_name);
            }
          }
        }
        //Eof add data attribute to the input for later data layer use

        */

        brand.lat = mapLAT
        brand.lng = mapLNG

        document.getElementById('loading-container-map-only').classList.remove('visible')
        brand.checkDragMap = false

        if (brand.premiseTypeID && brand.brandFlavor) {
          brand.getStoreList()
        } else {
          document.getElementById('flavor-list').innerHTML = ''
          !!brand.map && brand.removeMarkers()
        }
      });

      brand.map.addListener('dragend', function () {
        if (brand.premiseTypeID && brand.brandFlavor) {
          brand.lat = brand.map.center.lat()
          brand.lng = brand.map.center.lng()
          brand.getStoreList()
          brand.checkDragMap = true
          document.getElementById('loading-container').classList.remove('visible')
          document.getElementById('loading-container-map-only').classList.add('visible')
        }
      })

      if (brand.businesses !== null) {
        brand.addMarkers()
      }
    },

    getStoreList: async (isDrag) => {
      try {
        let data = {
          URL: brand.getStoreListForURL,
          BrandFlavor: brand.brandFlavor,
          Latitude: brand.lat,
          Longitude: brand.lng,
          PremiseTypeID: brand.premiseTypeID,
          MaxBusinesses: 50,
        }

        document.getElementById('loading-container').classList.add('visible')
        // eslint-disable-next-line no-unused-vars
        const response = await axios.get(data.URL + '?SearchQuery=' + data.BrandFlavor + '&Latitude=' + data.Latitude + '&Longitude=' + data.Longitude + '&PremiseTypeID=' + data.PremiseTypeID + '&MaxBusinesses=' + data.MaxBusinesses).then((response) => {
          const responseData = response.data
          if (responseData.ResponseCode === 0) {
            document.getElementById('form-no-results').classList.remove('visible')

            brand.lat = responseData.MapSettings.MapCenter.Latitude
            brand.lng = responseData.MapSettings.MapCenter.Longitude

            !!brand.map && brand.removeMarkers()

            brand.businesses = responseData.Businesses
            brand.locations = responseData.Locations

            if (!brand.map) {
              brand.loadMap()
            } else {
              brand.addMarkers()
            }

            !isDrag && brand.map.panTo(new google.current.maps.LatLng(brand.lat, brand.lng))
            // isDrag && brand.map.panTo(new google.current.maps.LatLng(brand.lat, brand.lng))

            brand.populateBusinessList()

            // Data layer - Locator address searched
            /*
            let inputAddress = brand.addressInputIDelm
            let addr = (inputAddress.getAttribute('data-st-num')) ? inputAddress.getAttribute('data-st-num') : ""
            addr = (inputAddress.getAttribute('data-st-name')) ? addr + " " + inputAddress.getAttribute('data-st-name') : addr

            let addressData = {
              addr: `${addr}`,
              cty: (inputAddress.getAttribute('data-cty')) ? inputAddress.getAttribute('data-cty') : "",
              st: (inputAddress.getAttribute('data-st')) ? inputAddress.getAttribute('data-st') : "",
              ctry: (inputAddress.getAttribute('data-ctry')) ? inputAddress.getAttribute('data-ctry') : "",
              zc: (inputAddress.getAttribute('data-zc')) ? inputAddress.getAttribute('data-zc') : ""
            }

            let brandSelect = brand.productFinderBrandSelector
            let flavorSelect = brand.productFinderFlavorSelector
            let deliveryMethodSelect = brand.productFinderPremiseSelector

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              "event": "locator_address_searched",
              "brand_name": brandSelect.options[brandSelect.selectedIndex].text.toLowerCase(),
              "product_type": flavorSelect.options[flavorSelect.selectedIndex].text.toLowerCase(),
              "search_method": deliveryMethodSelect.options[deliveryMethodSelect.selectedIndex].text.toLowerCase(),
              "dtls": {
                "cus": {
                  "addr": addressData.addr.toLowerCase(),
                  "cty": addressData.cty.toLowerCase(),
                  "st": addressData.st.toLowerCase(),
                  "ctry": addressData.ctry.toLowerCase(),
                  "zc": addressData.zc.toLowerCase()
                }
              }
            })
            */
            // Eof data layer - locator address searched

          } else {
            document.getElementById('flavor-list').innerHTML = ''
            !!brand.map && brand.removeMarkers()
            document.getElementById('loading-container').classList.remove('visible')
            document.getElementById('form-no-results').innerHTML = responseData.Message
            document.getElementById('form-no-results').classList.add('visible')
          }
        })
      } catch (error) {
        console.log("here catch ", error)
        document.getElementById('flavor-list').innerHTML = ''
        !!brand.map && brand.removeMarkers()
        document.getElementById('loading-container').classList.remove('visible')
        document.getElementById('form-no-results').innerHTML = 'No results found. Please try again later.'
        document.getElementById('form-no-results').classList.add('visible')
      }
    },

    removeMarkers: () => {
      for (let i in brand.businesses) {
        brand.businesses[i].marker.setMap(null)
      }
    },

    addMarkers: () => {
      brand.businesses.forEach(function (item, index) {
        item.marker = new google.current.maps.Marker({
          position: new google.current.maps.LatLng(item.Latitude, item.Longitude),
          map: brand.map,
          id: 'marker-' + index,
          target: 'marker-list-' + index,
          icon: brand.brandIconMapPin
        })

        google.current.maps.event.addListener(item.marker, 'click', function () {
          window.open('//maps.google.com/maps?saddr=My+Location&daddr=' + encodeURI(item.Address + ' ' + item.Zip), '_blank')

          // Data layer - Locator Addresse Searched
          /*
          var brandSelect = document.getElementById('product-finder-brand-selector');
          var flavorSelect = document.getElementById('product-finder-flavor-selector');

          var flavorName = brandSelect.options[brandSelect.selectedIndex].text + ' - ' + flavorSelect.options[flavorSelect.selectedIndex].text;
          if (flavorName !== "" && item.Name !== "") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              "event": "locator_supplier_selected",
              "supplier_name": item.Name.toLowerCase(),
              "product_flavor": flavorName.toLowerCase()
            });
          }
          */
          // Eof data layer - locator addresses searched
        })

        google.current.maps.event.addListener(item.marker, 'mouseover', function () {
          this.setIcon(brand.brandIconMapPinLarge)

          let listItem = document.getElementById(this.target)
          let parentlistItem = listItem.parentElement
          parentlistItem.classList.add('location-active')
          document.getElementById('flavor-list').scrollTop = listItem.offsetTop - 30
        })

        google.current.maps.event.addListener(item.marker, 'mouseout', function () {
          this.setIcon(brand.brandIconMapPin)
          let listItem = document.getElementById(this.target)
          let parentlistItem = listItem.parentElement
          parentlistItem.classList.remove('location-active')
        })
      })
    },

    getLocationFromIP: async () => {
      try {
        const config = {
          url: 'https://ipapi.co/jsonp?key=6e97326b5f08f95bb5e5bad3a3e26f297a989090',
          type: 'GET'
        }

        // var inputAddress = document.getElementById('product-finder-address');
        // eslint-disable-next-line no-unused-vars
        const response = await axios(config).then((response) => {
          if (response) {
            let responseDataString = response.data.split("(")[1];
            responseDataString = responseDataString.split(")")[0];
            const responseDataObj = JSON.parse(responseDataString);

            brand.lat = responseDataObj.latitude
            brand.lng = responseDataObj.longitude

            let addressInputID = brand.addressInputIDelm
            let cityName = responseDataObj.city
            let provinceName = responseDataObj.region
            let countryName = responseDataObj.country
            let defaultAddressStr = cityName + ', ' + provinceName + ', ' + countryName

            addressInputID.value = defaultAddressStr

            // get default data layer field
            // inputAddress.setAttribute('data-ctry', (countryName) ? countryName : "");
            // inputAddress.setAttribute("data-st", (provinceName) ? provinceName : "");
            // inputAddress.setAttribute("data-cty", (cityName) ? cityName : "");
            // eof get default data layer field

            brand.loadMap()

          } else {
            let addressInputID = brand.addressInputIDelm
            brand.lat = 49.2659
            brand.lng = -123.0920
            addressInputID.value = 'Vancouver, British Columbia, CA'

            // get default data layer field
            // inputAddress.setAttribute('data-ctry', 'CA');
            // inputAddress.setAttribute("data-st", 'BC');
            // inputAddress.setAttribute("data-cty", 'Vancouver');
            // eof get default data layer field

            brand.loadMap()
          }
        })
      } catch (error) {
        let addressInputID = brand.addressInputIDelm
        brand.lat = 49.2659
        brand.lng = -123.0920
        addressInputID.value = 'Vancouver, British Columbia, CA'

        brand.loadMap()
      }
    },

    brandSelector: (productArr) => {
      brand.productFinderFlavorSelector = document.getElementById('product-finder-flavor-selector')
      brand.productFinderPremiseSelector = document.getElementById('product-finder-premise-selector')
      if (!productArr || productArr.length === 0) {
        // brand.productFinderFlavorSelector.setAttribute('disabled', false)
        // brand.productFinderFlavorSelector.classList.add('search-input--disabled')

        // let options = ''
        // options += '<option value="null">Select A Product</option>'
        // brand.productFinderFlavorSelector.innerHTML = options
        // document.getElementById('flavor-list').innerHTML = ''
        // !!brand.map && brand.removeMarkers()

        // brand.productFinderPremiseSelector.setAttribute('disabled', false)
        // brand.productFinderPremiseSelector.classList.add('search-input--disabled')
        return
      }

      brand.productFinderFlavorSelector.removeAttribute('disabled')
      brand.productFinderFlavorSelector.classList.remove('search-input--disabled')

      const selectedBrandFlavors = []
      for (let i in productArr) {
        let productId = productArr[i].toString()

        for (const key in groupedBrandFlavors) {

          if (key === productId) {
            selectedBrandFlavors.push(groupedBrandFlavors[productId])
            let options = ''
            options += '<option value="">Select A Product</option>'

            selectedBrandFlavors.forEach(function (item, index) {
              item.forEach(function (itemChild, index) {
                if (itemChild.Name.includes("All Products")) {
                  options += '<option value="' + itemChild.Key + '" class="flavor-selector-option-all">' + itemChild.Name + '</option>'
                } else {
                  options += '<option value="' + itemChild.Key + '">' + itemChild.Name + '</option>'
                }
              })
            })

            brand.productFinderFlavorSelector.innerHTML = options
            document.getElementById('flavor-list').innerHTML = ''
            !!brand.map && brand.removeMarkers()
          }
        }
      }
    },

    flavorSelector: () => {
      brand.productFinderFlavorSelector.addEventListener('change', function () {
        brand.productFinderPremiseSelector.removeAttribute('disabled')
        brand.productFinderPremiseSelector.classList.remove('search-input--disabled')
        brand.brandFlavor = this.value
        if (brand.premiseTypeID && brand.brandFlavor) {
          brand.getStoreList()
        } else {
          document.getElementById('flavor-list').innerHTML = ''
          !!brand.map && brand.removeMarkers()
        }
      })
    },

    premiseTypeSelect: () => {
      brand.productFinderPremiseSelector.addEventListener('change', function () {
        brand.premiseTypeID = this.value
        if (brand.premiseTypeID && brand.brandFlavor) {
          brand.getStoreList()
        } else {
          document.getElementById('flavor-list').innerHTML = ''
          !!brand.map && brand.removeMarkers()
        }
      })

      // Mobile device user location getter function event listener
      // Currently it is not set nor it is part of the mobile experience
      // brand.locateMeBtn.addEventListener('click', function () {
      //   brand.requestLocation()
      // })
    },

    init: function () {
      brand.getDomElems()
      brand.setLocationBasedVariableValues()
      brand.locatorResults()
      brand.getLocationFromIP()
      brand.getBrands()
      brand.brandSelector()
      brand.flavorSelector()
      brand.premiseTypeSelect()
    }
  }

  useEffect(() => {

    if (isElementInViewport(refSearchElmsSection.current)) {
      setIsInView(true)

      const waitForGoogleWindowObj = () => {
        google.current = window.google
        if (google.current) {
          brand.init()
        } else {
          setTimeout(waitForGoogleWindowObj, 250)
        }
      }

      waitForGoogleWindowObj()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectStyles = {
    container: (base, state) => ({
      ...base,
      // Below lines left as references for possible styling methods
      // zIndex: "9999",
      // border: state.isFocused ? "2px solid lime" : "5px solid lime",
      // boxShadow: state.isFocused ? "#34332d" : "#34332d",
      // borderColor: state.isFocused ? "yellow" : "green",
      // borderColor: state.isBlur ? "yellow" : "green",
    })
  }

  return (
    <>
      <section className={(isInView) ? 'search-elements-container d-flex flex-wrap justify-content-center align-items-center position-relative in-view' : 'search-elements-container d-flex flex-wrap justify-content-center align-items-center position-relative'} ref={refSearchElmsSection}>
        <div className="container container-locator mb-5 slide-up">
          <div className="tlt-row row">
            <div className="tlt-wrapper col-12 col-lg-6">
              <h2>{pageTitle}</h2>
            </div>
            <div className="col-12 col-lg-6">
              <div className="desc-wrapper w-100">
                <p>{pageCopy}</p>
              </div>
            </div>
          </div>
        </div>

        <div id="product-finder-form" className="container container-locator slide-up">
          <div className="row">

            <div className="col-12 col-lg-12 form-group mb-2 mb-lg-2">
              <div className="input-group search-input-container">
                <label htmlFor="productFinderAddress" className="search-input-label">1: Where are you?</label>
                <input id="product-finder-address" className="form-control search-input" name="productFinderAddress" placeholder="Enter Your Address" type="search" />
              </div>
            </div>

            <div className="col-12 col-lg-4 form-group mb-2 mb-lg-2">
              <div className="input-group react-search-input-container">
                <label htmlFor="productFinderBrandSelector" className="search-input-label">2: What brands are you looking for?</label>
                <Select
                  name="productFinderBrandSelector"
                  id="product-finder-brand-selector"
                  className="form-control select-input react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select A Brand"
                  value={productFinderReactSelectOptions.filter(obj => selectedOptions.includes(obj.value))} // set selected values
                  options={productFinderReactSelectOptions} // set list of the data
                  onChange={brandLocatorOnChange} // assign onChange function
                  isMulti
                  isClearable
                  closeMenuOnSelect={true}
                  isOptionDisabled={() => selectedOptions.length >= 3} // limit selected items to max 3
                  styles={selectStyles}
                  theme={(theme) => ({
                    ...theme,
                    position: "relative",
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#48463E',
                      primary: '#34332d',
                      neutral0: '#34332d',
                      primary50: '#48463E'
                    }
                  })}
                />
              </div>
            </div>

            {/*
            To be used later, move react-select to its own component
            <ReactSelect optionsData={productFinderReactSelectOptions} />
            */}

            <div className="col-12 col-lg-4 form-group mb-2 mb-lg-0">
              <div className="input-group search-input-container">
                <label htmlFor="productFinderFlavorSelector" className="search-input-label">3: What types of wine?</label>
                <select className="form-control select-input search-input--disabled" id="product-finder-flavor-selector" name="productFinderFlavorSelector" aria-label="Flavor Selector" disabled>
                  <option value="null">Select A Product</option>
                </select>
              </div>
            </div>

            <div className="col-12 col-lg-4 form-group mb-2 mb-lg-0">
              <div className="input-group search-input-container">
                <label htmlFor="productFinderPremiseSelector" className="search-input-label">4: What kind of store?</label>
                <select className="form-control select-input search-input--disabled" id="product-finder-premise-selector" name="productFinderPremiseSelector" aria-label="Premise Selector" disabled>
                  <option value="">Select A Store Type</option>
                  <option value="-1">ALL</option>
                  <option value="1">Restaurants/Bars</option>
                  <option value="0">Retail Stores</option>
                </select>
              </div>
            </div>
            {/*
            <div className="col-12 d-lg-none form-group mb-lg-0">
              <button id="product-finder-locate-me" className="btn w-100" type="button">Near Me</button>
            </div>
            */}
          </div>
        </div>
      </section>

      <section id="map-section" className="map container-fluid position-relative px-0">

        <div className="position-relative">
          <div id="loading-container" className="map-loader-common">
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>

          <div id="form-no-results" className="error-bubble">No results found for this address.</div>

          <div className="row d-flex d-lg-none mx-0 px-0">
            <div className="text-center d-flex flex-column text-center justify-content-center col col-6 d-flex mx-0 px-0">
              <button className={(tabMapBtnDisabled)
                ? 'btn btn-tab active' : 'btn btn-tab'} onClick={mapClickfunc} disabled={tabMapBtnDisabled}>
                MAP
              </button>
            </div>
            <div className="text-center d-flex flex-column text-center justify-content-center col col-6 d-flex mx-0 px-0">
              <button className={(tabListBtnDisabled)
                ? 'btn btn-tab active' : 'btn btn-tab'} onClick={listClickfunc} disabled={tabListBtnDisabled}>
                LIST
              </button>
            </div>
          </div>

          <div className="row map-list-container mx-0 px-0">

            <div id="main-locator-results" className={(listWrapActive)
              ? 'inactive col-12 col-lg-6 main-locator-results position-relative mx-0 px-0'
              : 'active col-12 col-lg-6 main-locator-results position-relative mx-0 px-0'}>

              <div id="flavor-list">
                <div className="pre-search-result-container text-left px-4 py-4">
                  <p className="message">Enter an address to find wines nearby</p>
                </div>
              </div>
            </div>

            <div className={(mapWrapActive)
              ? 'active col-12 col-lg-6 mx-0 px-0 map__wrap'
              : 'inactive col-12 col-lg-6 mx-0 px-0 map__wrap'}>

              <div id="map-canvas" className="map-blocks-common map__map"></div>
              <div id="loading-container-map-only" className="map-loader-common">
                <div className="spinner spinner-map-only">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>

      <section className="map-bottom-banner d-flex justify-content-center align-items-center">

        <div className="container container-locator">
          <div className="row">
            <div className="col-12 text-center d-flex flex-column text-center justify-content-center">
              <h2>Can't find what you're looking for?</h2>
              <p>Ask us a question by sending us a message, we're happy to help you find <br className="d-none d-md-block d-lg-none" /> wines you love.</p>
              <div>
                <a href="/contact-us" className="flex-grow-0">
                  <button className="btn btn-primary-2 mx-0 mb-0">
                    <span>Contact us</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default ProductLocator