import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import ProductLocator from '../components/productlocator/ProductLocator'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

//components
const loadScript = (url) => {
  return new Promise(function (resolve, reject) {
    let script = document.createElement('script')
    script.src = url
    script.async = false
    script.onload = function () {
      resolve(url)
    }
    script.onerror = function () {
      reject(url)
    }
    document.body.appendChild(script)
  })
}

let scripts = [
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDz5HodfB1uehEStG-DkPgmGLx_ePVworA&libraries=places'
]

// save all Promises as array
let promises = []

scripts.forEach(function (url) {
  promises.push(loadScript(url))
})

Promise.all(promises).then(function () {
  console.log('all scripts loaded')
}).catch(function (script) {
  console.log(script + ' failed to load')
})

const WhereToBuy = ({ data }) => {
  const pageData = data.prismicWhereToBuy.data
  return (
    <Layout currentPage="where-to-buy">
      <Seo
        title={(pageData.meta_title) ? pageData.meta_title.text : null}
        description={(pageData.meta_description) ? pageData.meta_description.text : null}
      />
      {/*
      */}
      <ProductLocator pageTitle={(pageData.page_title.text)} pageCopy={(pageData.page_copy.text)} />
    </Layout>
  )
}


export const query = graphql`
    query WhereToBuyQuery {
        prismicWhereToBuy {
            _previewable
            data {
                meta_description {
                    text
                }
                meta_title {
                    text
                }
                page_title {
                    text
                    html
                }
                page_copy {
                    text
                }
            }
        }
    }
`

export default withPrismicPreview(WhereToBuy)